@font-face {
  font-family: "RNSLango";
  src: url("/public/fonts/rnslango-light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "RNSLango";
  src: url("/public/fonts/rnslango-lightitalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "RNSLango";
  src: url("/public/fonts/rnslango-medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RNSLango";
  src: url("/public/fonts/rnslango-mediumitalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "RNSLango";
  src: url("/public/fonts/rnslango-bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "RNSLango";
  src: url("/public/fonts/rnslango-bolditalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "RNSNuxai";
  src: url("/public/fonts/rnsnuxai-regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
