@import "./fonts.css";

:root {
  --main-font: "RNSLango", sans-serif;
  --secondary-font: "RNSNuxai", sans-serif;
  --color-dark: #2c2e43;
}

body {
  font-family: var(--main-font);
}

/* SWITCH */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #e62d40;
}

input:focus + .slider {
  box-shadow: 0 0 1px #e62d40;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* APP */

.App {
  z-index: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-image: url("/public/images/home-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: fixed;
  top: 0;
  left: 0;
}

.App.homeBackground {
  transition: background-image 0.5s ease-in-out;
}

.App.homeBackground.overlay:before {
  content: "";
  background-color: rgb(0, 0, 0);
  opacity: 0.4;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.App h1 {
  color: white;
  font-size: 2rem;
}

.App::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(5, 5, 5, 0.5), transparent),
    linear-gradient(
      to top,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.75) 20%,
      transparent 50%
    );
  background-size: 100% 15%, 100% 80%;
  background-repeat: no-repeat;
  background-position: top, bottom;
}

.App-logo {
  margin-top: 60px;
  height: 120px;
  pointer-events: none;
  z-index: 9;
}

.App-header {
  height: 120px;
  display: flex;
  align-items: center;
  color: white;
}

.App-form-section {
  z-index: 2;

  &.bordered {
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 16px;
    padding: 1rem;
    margin-top: 2rem;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
  }

  &.shadowed {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }

  &.semi-transparent {
    background-color: rgba(0, 0, 0, 0.4);
  }

  &.form-box {
    padding: 0.5rem 2rem;
  }
}

.App-input-group {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 2rem;

  label {
    color: white;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;

    span {
      font-size: 1rem;
      font-weight: 300;
    }
  }

  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    font-family: var(--secondary-font);
    text-align: center;
    outline-color: #e62d40;
    border-radius: 32px;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    -ms-border-radius: 32px;
    -o-border-radius: 32px;
  }

  &.centered {
    background-color: red !important;
    text-align: center;
    justify-content: center;
  }
}

.App-switch-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  color: rgba(255, 255, 255, 0.5);

  .active {
    color: white;
  }
}

.App-text-animated {
  color: white;
  font-size: 1.25rem;
  font-weight: 400;
  font-family: var(--secondary-font);
}

.App-text-animated-wrapper {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-button {
  background-color: #e62d40;
  border: none;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  color: white;
  margin-top: 0.5rem;
  padding: 8px 24px;
  z-index: 9;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  font-size: 1.5rem;
  font-family: var(--secondary-font);

  &:hover {
    cursor: pointer;
    background-color: #e62d4080;
  }

  &:disabled {
    background-color: #e62d4080;
    cursor: not-allowed;
  }
}

.App-link {
  color: #61dafb;
}

.App-footer {
  width: 100%;
  height: 120px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-size: 12px;
}

.App-footer .social_profiles {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.App-footer .social_profiles .social_profile_item {
  /* background-color: #e62d4080; */
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;

  &:hover {
    background-color: #e62d40;
  }
}

.App-footer .social_profiles .social_profile_item a {
  color: white;
}

@media (min-width: 768px) {
  .App h1 {
    font-size: 4rem;
  }
  .App-text-animated {
    font-size: 2rem;
  }
  .App-button {
    font-size: 2rem;
    margin-top: 2rem;
  }
  .App-footer {
    font-size: 16px;
  }
  .App-form-section.form-box {
    padding: 2rem 4rem;
  }
}

@media (min-width: 1200px) {
  .App h1 {
    font-size: 6rem;
  }
  .App-text-animated {
    font-size: 2rem;
  }
  .App-text-animated {
    font-size: 2.5rem;
  }
}
